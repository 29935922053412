<template>
  <div class="w-full h-full flex container justify-center items-center mt-10">
    <div>
      <h2 class="font-bold fs-20">
        {{ $t("Yêu cầu đồng thuận từ Bệnh nhân") }}
      </h2>
      <div class="text-black">
        <p>
          <strong>{{
            $t("Bệnh nhân đồng ý chia sẻ thông tin chỉ định y khoa. Cụ thể là:")
          }}</strong>
        </p>
        <ol>
          <li style="word-break: break-word">
            {{
              $t(
                "Bệnh nhân chấp thuận cho phép Bác Sĩ, Nhân Viên Y Tế yêu cầu dịch vụ cận lâm sàng tại Đơn vị này (đã cung cấp trong nội dung tin nhắn OTP)"
              )
            }}
          </li>
          <li class="mt-2" style="word-break: break-word">
            {{
              $t(
                " Bệnh nhân chấp thuận cho phép Bác Sĩ, Nhân Viên Y Tế, Cơ Sở Y Tế, Cơ Sở Cung Cấp Dịch Vụ Y Tế và Nền Tảng Công Nghệ Thông Tin chia sẻ dữ liệu liên quan đến chỉ định y khoa. Cụ thể là:"
              )
            }}
            <ul>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu hành chính (bao gồm thông tin cá nhân)") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu chẩn đoán") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu lâm sàng kèm theo") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu chỉ định y khoa") }}
              </li>
            </ul>
          </li>
        </ol>
        <p style="word-break: break-word; font-style: italic">
          {{
            $t(
              "Lưu ý: Dữ liệu được chia sẻ hoàn toàn nhằm mục đích đảm bảo chất lượng Dịch Vụ Xét Nghiệm, đảm bảo an toàn Bệnh Nhân, Nhân Viên Y Tế, và các Đơn Vị Cung Cấp Dịch Vụ."
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndicationConsentForm'
}
</script>

<style lang="scss" scoped>
</style>
